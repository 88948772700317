import {Injectable} from '@angular/core';
import {KommDataModel, ProgramParticipationStatus, ProgramParticipationType} from '../../core/models/kommdata.model';

@Injectable({
  providedIn: 'root'
})
export class ProgramUtilService {

  constructor() {
  }

  public hasMaz(kommData: KommDataModel): boolean {
    return this.hasProgramWithStatus(kommData, ProgramParticipationType.MAZ, ProgramParticipationStatus.GREEN);
  }

  public hasWEWE(kommData: KommDataModel): boolean {
    return this.hasProgramWithStatus(kommData, ProgramParticipationType.WEWE, ProgramParticipationStatus.GREEN);
  }

  public hasDEWE(kommData: KommDataModel): boolean {
    return this.hasProgramWithStatus(kommData, ProgramParticipationType.DEWE, ProgramParticipationStatus.GREEN)
      || this.hasProgramWithStatus(kommData, ProgramParticipationType.DEWE_PLUS, ProgramParticipationStatus.GREEN);
  }

  public isDeweRelevant(kommData: KommDataModel): boolean {
    return !this.hasProgramWithStatus(kommData, ProgramParticipationType.DEWE, ProgramParticipationStatus.NOT_REQUIRED);
  }

  private hasProgramWithStatus(kommData: KommDataModel, programType: ProgramParticipationType, status: ProgramParticipationStatus) {
    return kommData.participations.find(participation => participation.type === programType)?.status
      === status;
  }
}
