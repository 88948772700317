import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AuthorizedRepresentative} from '../../../../core/models/kommdata.model';

@Component({
  selector: 'dsmkd-identified-check',
  templateUrl: './identified-check.component.html',
  styleUrls: ['./identified-check.component.scss']
})
export class IdentifiedCheckComponent {
  @Input() customerName: string;
  @Input() showError: boolean;

  touched: boolean;

  roleOptions = ['Geschäftsführer:in', 'Gesellschafter:in-Geschäftsführer:in', 'Gesellschafter:in',
    'Vorständ:in', 'Inhaber:in', 'Teilhaber:in', 'Prokurist:in', 'Direktor:in',
    'Leiter:in', 'Bevollmächtigte:r Mitarbeiter:in'];

  private currentIsIdent: boolean;
  currentAuthRep: AuthorizedRepresentative;

  @Output() isIdentChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() authRepChange: EventEmitter<AuthorizedRepresentative> = new EventEmitter<AuthorizedRepresentative>();

  constructor() {
  }

  @Input() set isIdent(isIdent: boolean) {
    this.currentIsIdent = isIdent;
    this.isIdentChange.emit(isIdent);
  }

  get isIdent(): boolean {
    return this.currentIsIdent;
  }

  @Input() set authRep(authRep: AuthorizedRepresentative) {
    this.currentAuthRep = authRep;
    this.authRepChange.emit(authRep);
  }

  get authRep(): AuthorizedRepresentative {
    return this.currentAuthRep;
  }

  optOutStatusChange($event: any) {
    this.isIdentChange.emit($event);
    this.touched = true;
  }

  markAsTouched(): void {
    this.touched = true;
  }

  showIdentificationCheckError(): boolean {
    return !this.isIdent && (this.touched || this.showError);
  }
}
